









































































































































































































































































.box-saldo {
  color: #FFF;
  &-titulo {
    text-align: center;
    font-size: .9rem;
    width: 100%;
    display: flex;
    padding-top: .4rem;
    justify-content: center;

    &-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__content {
    text-align: center;
   span {
     color: #Fff;
     display: block;
   }

   strong {
     color: #Fff;
     font-size: 1.6rem;
   }
  }
}

.ocultar-dados {
  font-size: 4.6rem;
  /* line-height: 0; */
  display: inline-block !important;
  line-height: 0;
}
